.theme-toggle.theme-toggle--reversed .theme-toggle__classic {
    transform: scale(-1, 1);
}

.theme-toggle {
    --theme-toggle__classic--duration: 500ms;
}

.theme-toggle__classic path {
    transition-timing-function: cubic-bezier(0, 0, 0.15, 1.25);
    transform-origin: center;
    transition-duration: calc(var(--theme-toggle__classic--duration) * 0.8);
}

.theme-toggle__classic g path {
    transition-property: opacity, transform;
    transition-delay: calc(var(--theme-toggle__classic--duration) * 0.2);
}

.theme-toggle__classic :first-child path {
    transition-property: transform, d;
}

.theme-toggle--toggled:not(label).theme-toggle .theme-toggle__classic g path,
.dark .theme-toggle .theme-toggle__classic g path {
    transform: scale(0.5) rotate(45deg);
    opacity: 0;
    transition-delay: 0s;
}

.theme-toggle--toggled:not(label).theme-toggle .theme-toggle__classic :first-child path,
.dark .theme-toggle .theme-toggle__classic :first-child path {
    d: path("M-12 5h30a1 1 0 0 0 9 13v24h-39Z");
    transition-delay: calc(var(--theme-toggle__classic--duration) * 0.2);
}

@supports not (d: path("")) {

    .theme-toggle--toggled:not(label).theme-toggle .theme-toggle__classic :first-child path,
    .dark .theme-toggle .theme-toggle__classic :first-child path {
        transform: translate3d(-12px, 10px, 0);
    }
}

.theme-toggle{  
    border: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    padding: 10px;
    color: hsl(var(--text-h), var(--text-s), calc(var(--text-l) + 30% ));
}

.theme-toggle:hover{ 
    color: hsl(var(--text-h), var(--text-s), calc(var(--text-l) + 10% ));
    background-color: hsl(var(--background-h), var(--background-s), calc(var(--background-l) - 8% ));
}

.dark .theme-toggle{
    padding: 7px;
    color: hsl(var(--text-h), var(--text-s), calc(var(--text-l) - 30% ));
}

.dark .theme-toggle:hover{ 
    color: hsl(var(--text-h), var(--text-s), calc(var(--text-l) - 5% ));
    background-color: hsl(var(--background-h), var(--background-s), calc(var(--background-l) - 8% ));
}

.theme-toggle svg{
    width: 100%;
    height: 100%;
}

@media (prefers-reduced-motion: reduce) {
    .theme-toggle:not(.theme-toggle--force-motion) * {
        transition: none !important;
    }
}